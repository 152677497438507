import { useFetchPosts } from "../lib/useFetchPosts";
import CreatePost from "./CreatePost";
import PostCard from "./PostCard";

export default function PostsList() {
  const { posts, loading, createPost } = useFetchPosts();

  if (loading) {
    return <p>Loading Posts</p>;
  }

  return (
    <div className="flex gap-4">
      <div className="w-1/3 mt-4">
        <h2 className="text-2xl mb-4">Create a Post</h2>
        <CreatePost onSubmit={createPost} />
      </div>

      <div className="flex-1">
        <ul className="space-y-8 mt-12">
          {posts.map((post) => (
            <PostCard post={post} key={post.id} />
          ))}
        </ul>
      </div>
    </div>
  );
}
