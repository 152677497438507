import { useState } from "react";
import { Post } from "../lib/useFetchPosts";

interface CreatePostProps {
  onSubmit: (post: Omit<Post, "id">) => void;
}

export default function CreatePost({ onSubmit }: CreatePostProps): JSX.Element {
  const [title, setTitle] = useState("");
  const [username, setUsername] = useState("");
  const [content, setContent] = useState("");

  const [imageUrl, setImageUrl] = useState("");
  const [link, setLink] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit({
          title,
          username,
          content,
          imageUrl,
          link,
        });

        // Reset fields
        setTitle("");
        setUsername("");
        setContent("");
        setImageUrl("");
        setLink("");
      }}
    >
      <label className="input-group">
        <h2>Title</h2>

        <input
          type="text"
          value={title}
          required
          onChange={(e) => setTitle(e.target.value)}
        />
      </label>

      <label className="input-group">
        <h2>Username</h2>

        <input
          type="text"
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
      </label>

      <label className="input-group">
        <h2>Content</h2>

        <textarea
          value={content}
          required
          onChange={(e) => setContent(e.target.value)}
        />
      </label>

      <label className="input-group">
        <h2>Image Link (optional)</h2>

        <input
          type="url"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
        />
      </label>

      <label className="input-group">
        <h2>Link to an external website? (optional)</h2>

        <input
          type="url"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
      </label>

      <input
        type="submit"
        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors cursor-pointer"
      />
    </form>
  );
}
