import React from "react";
import { Post } from "../lib/useFetchPosts";

interface PostCardProps {
  post: Post;
}

export default function PostCard({ post }: PostCardProps): JSX.Element {
  return (
    <li className="bg-yellow-50 p-4 rounded-md">
      <h1 className="text-xl font-semibold">
        {post.link ? (
          <a
            href={post.link}
            target="_blank"
            rel="noreferrer"
            className="text-red-400"
          >
            {post.title} 🔗
          </a>
        ) : (
          post.title
        )}
      </h1>

      <h2 className="italic text-sm text-gray-600 mb-2">@{post.username}</h2>

      {post.imageUrl && (
        <img
          src={post.imageUrl}
          className="w-full block my-4"
          alt={`Cover for ${post.title}`}
        />
      )}

      <p>{post.content}</p>
    </li>
  );
}
