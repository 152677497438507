import React from "react";

export default function Header(): JSX.Element {
  return (
    <header
      style={{
        background: "linear-gradient(to right, #e53935, #e35d5b)",
      }}
      className="text-2xl font-semibold h-48 rounded-md text-center flex items-center justify-center text-white"
    >
      Fardeem's Amazing Social Media
    </header>
  );
}
