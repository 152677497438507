import { useEffect, useState } from "react";

export type Post = {
  id: string;
  title: string;
  content: string;
  username: string;
  imageUrl?: string | null;
  link?: string | null;
};

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://workers.fardeem.workers.dev"
    : "";

export function useFetchPosts() {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`${BASE_URL}/posts`)
      .then((res) => {
        res.json().then((data) => {
          setPosts(data);
          setLoading(false);
        });
      })
      .catch((err) => {
        console.error("Error fetching posts", err);
      });
  }, []);

  function createPost(post: Omit<Post, "id">) {
    fetch(`${BASE_URL}/posts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(post),
    })
      .then(() => {
        setPosts([
          ...posts,
          // We don't have the id yet, so we'll just set it to a random number
          { ...post, id: String(Math.random()) },
        ]);
      })
      .catch((err) => {
        console.error("Error creating post", err);
      });
  }

  return {
    posts,
    loading,
    createPost,
  };
}
