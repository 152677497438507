import Header from "./components/Header";
import PostsList from "./components/PostsList";

function App() {
  return (
    <div className="w-full max-w-5xl p-4 mt-8 mx-auto">
      <Header />

      <PostsList />
    </div>
  );
}

export default App;
